import React from "react";
import theme from "theme";
import { Theme, Link, Image, Text, Box, Section, Strong, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Inner Trail | Hidden Jem Games
			</title>
			<meta name={"description"} content={"Inner Trail is a soft survival game where you learn to navigate the wild, confront your inner struggles, and find harmony with nature. Forage, farm, fight, and thrive as you uncover hidden secrets, surprises, and chance encounters. Along the trails you follow, take time to reflect—because in the end, every choice you make shapes who you are."} />
			<meta property={"og:title"} content={"Inner Trail | Hidden Jem Games"} />
			<meta property={"og:description"} content={"Inner Trail is a soft survival game where you learn to navigate the wild, confront your inner struggles, and find harmony with nature. Forage, farm, fight, and thrive as you uncover hidden secrets, surprises, and chance encounters. Along the trails you follow, take time to reflect—because in the end, every choice you make shapes who you are."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/Only%20Title%20Screen.png?v=2024-12-22T10:17:20.428Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/favicon-32x32.png?v=2024-12-22T19:36:48.147Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/apple-touch-icon.png?v=2024-12-22T19:36:55.185Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/apple-touch-icon.png?v=2024-12-22T19:36:55.185Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/apple-touch-icon.png?v=2024-12-22T19:36:55.185Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/apple-touch-icon.png?v=2024-12-22T19:36:55.185Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/android-chrome-192x192.png?v=2024-12-22T19:37:14.487Z"} />
			<meta name={"msapplication-TileColor"} content={"#F2EFEA"} />
		</Helmet>
		<Components.Header z-index="1000">
			<Override slot="linkBox" />
			<Override slot="image" />
			<Override slot="SectionContent" />
			<Override slot="box" />
			<Override slot="icon" />
			<Override slot="icon1" />
			<Override slot="icon2" />
			<Override slot="image1" />
			<Override slot="linkBox1" />
			<Override slot="linkBox3" />
			<Override slot="linkBox2" />
			<Override slot="linkBox4" />
		</Components.Header>
		<Section
			quarkly-title="HeroBlock"
			background="url(https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/Only%20Title%20Screen.png?v=2024-12-22T10:17:20.428Z) center/cover repeat scroll padding-box"
			sm-background="url(https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/Vertical%20w%20logo.png?v=2024-12-31T22:46:33.460Z) 60%/cover repeat scroll padding-box"
			padding="0 0 0 0"
			md-background="url(https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/Vertical%20w%20logo.png?v=2024-12-31T22:46:33.460Z) center/cover repeat scroll padding-box"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="40px"
				max-width="none"
				width="100%"
				sm-flex-direction="row"
				min-height="100vh"
				sm-min-width="none"
				order="0"
				align-self="auto"
				justify-content="center"
				align-items="flex-start"
				padding="0px 0px 0px 0px"
				display="flex"
				sm-min-height="100vh"
				position="relative"
				sm-align-items="flex-start"
				sm-justify-content="center"
				sm-display="flex"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				align-items="center"
				display="flex"
				flex-direction="column"
				justify-content="center"
				margin="80px 0 0px 0"
				md-width="100%"
				md-margin="100px 0 0px 0"
				width="100%"
				sm-width="100%"
				lg-width="100%"
				lg-overflow="hidden"
				lg-margin="80px 0 0px 0"
				sm-margin="110px 0 0px 0"
			>
				<Image
					src="https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/Logo.png?v=2024-12-22T10:17:38.899Z"
					display="block"
					height="140px"
					md-height="120px"
					lg-height="130px"
					sm-height="80px"
					srcSet="https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/Logo.png?v=2024-12-22T10%3A17%3A38.899Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/Logo.png?v=2024-12-22T10%3A17%3A38.899Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/Logo.png?v=2024-12-22T10%3A17%3A38.899Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/Logo.png?v=2024-12-22T10%3A17%3A38.899Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/Logo.png?v=2024-12-22T10%3A17%3A38.899Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/Logo.png?v=2024-12-22T10%3A17%3A38.899Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/Logo.png?v=2024-12-22T10%3A17%3A38.899Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
				/>
				<Text
					margin="20px 0px 0px 0px"
					font="bold 22px/1.5 --fontFamily-googlePressStart2P"
					color="#ffffff"
					text-shadow="3px 3px 0 --color-indigo"
					sm-font="bold 18px/1.5 --fontFamily-googlePressStart2P"
				>
					Coming to Steam!
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-secondary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" lg-justify-content="center" />
			<Box
				width="50%"
				lg-width="70%"
				lg-justify-content="center"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				md-justify-content="center"
				sm-justify-content="center"
				lg-display="flex"
				md-width="100%"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(9, 60px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					max-width="none"
					lg-margin="0px 0px 16px 0px"
					sm-grid-template-rows="repeat(9, 10vw)"
					lg-grid-template-rows="repeat(9, 80px)"
					lg-grid-template-columns="repeat(2, 1fr)"
				>
					<Image
						src="https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01:23:13.148Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-row="1 / span 5"
						grid-column="1 / span 1"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02:50:33.442Z"
						border-radius="24px"
						object-fit="cover"
						grid-column="2 / span 1"
						grid-row="2 / span 3"
						width="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02%3A50%3A33.442Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02%3A50%3A33.442Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02%3A50%3A33.442Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02%3A50%3A33.442Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02%3A50%3A33.442Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02%3A50%3A33.442Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02%3A50%3A33.442Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/Image%20Sequence_006_0032.png?v=2024-12-22T10:16:23.634Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="1 / span 1"
						grid-row="6 / span 3"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/Image%20Sequence_006_0032.png?v=2024-12-22T10%3A16%3A23.634Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/Image%20Sequence_006_0032.png?v=2024-12-22T10%3A16%3A23.634Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/Image%20Sequence_006_0032.png?v=2024-12-22T10%3A16%3A23.634Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/Image%20Sequence_006_0032.png?v=2024-12-22T10%3A16%3A23.634Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/Image%20Sequence_006_0032.png?v=2024-12-22T10%3A16%3A23.634Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/Image%20Sequence_006_0032.png?v=2024-12-22T10%3A16%3A23.634Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/Image%20Sequence_006_0032.png?v=2024-12-22T10%3A16%3A23.634Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01:23:13.143Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="2 / span 1"
						grid-row="5 / span 5"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01%3A23%3A13.143Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01%3A23%3A13.143Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01%3A23%3A13.143Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01%3A23%3A13.143Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01%3A23%3A13.143Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01%3A23%3A13.143Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01%3A23%3A13.143Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 36px"
				lg-width="70%"
				lg-padding="24px 0px 0px 0px"
				md-width="100%"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					sm-font="--headline2"
					sm-align-self="flex-start"
					md-text-align="left"
					md-align-self="flex-start"
					lg-align-self="flex-start"
					lg-text-align="left"
					lg-font="--headline1Lg"
				>
					A Journey Within
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--lead"
					lg-text-align="left"
					md-text-align="left"
					lg-align-self="flex-start"
				>
					Lost in the wild, it’s you against nature.{" "}
					<br />
					Navigate the wild, confront inner struggles, and adapt to the world around you.{" "}
					<br />
					Forage, farm, fight, and thrive as you uncover hidden secrets, surprises, and chance encounters.
					<br />
					Along the trails you follow, take time to reflect—because in the end, every choice you make shapes who you are.{"\n\n "}
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark" background="--color-light">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text
							as="h2"
							font="--headline1"
							margin="0 0 8px 0"
							sm-font="--headline2"
							md-font="--headline1Lg"
							lg-font="--headline1Lg"
						>
							FAQs
						</Text>
						<Text as="p" font="--lead" margin="0" color="--greyD2">
							Need answers? We got’em
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline4" margin="12px 0" sm-font="--headline3Lg">
									When will the game be released?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--darkL1">
									Unfortunately, we don’t have an exact release date just yet. But we are working hard to get there and making progress everyday. Stay tuned and follow us for the latest updates!
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline4" margin="12px 0" sm-font="--headline3Lg">
									Will there be a demo?
									<br />
									{" "}
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--darkL1">
									<Strong
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
										color="--primary"
									/>
									Yes! An early access demo will be available soon, giving you a taste of the adventure.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline4" margin="12px 0" sm-font="--headline3Lg">
									Will the game be free to play?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--darkL1">
									The unlimited playtime demo will be free because we believe players should only pay if they enjoy the game. The full experience will be paid to support development and production costs, ensuring a high-quality experience.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline4" margin="12px 0" sm-font="--headline3Lg">
									How can I stay updated on the game’s progress?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--darkL1">
									Stay connected with us on
									<Link
										href="https://www.tiktok.com/@hiddenjem09"
										color="--primary"
										text-decoration-line="underline"
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
									>
										<Span
											overflow-wrap="normal"
											word-break="normal"
											white-space="normal"
											text-indent="0"
											text-overflow="clip"
											hyphens="manual"
											user-select="auto"
											pointer-events="auto"
										>
											Tiktok
										</Span>
									</Link>
									,{" "}
									<Link
										href="https://www.youtube.com/@innertrailgame"
										color="--primary"
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
									>
										<Span
											overflow-wrap="normal"
											word-break="normal"
											white-space="normal"
											text-indent="0"
											text-overflow="clip"
											hyphens="manual"
											user-select="auto"
											pointer-events="auto"
										>
											Youtube
										</Span>
									</Link>
									,{" "}
									<Link
										href="https://www.instagram.com/inner_trail_game"
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
										color="--color-primary"
									>
										<Span
											overflow-wrap="normal"
											word-break="normal"
											white-space="normal"
											text-indent="0"
											text-overflow="clip"
											hyphens="manual"
											user-select="auto"
											pointer-events="auto"
										>
											Instagram
										</Span>
									</Link>
									, and{" "}
									<Link
										href="https://x.com/innertrailgame"
										color="--color-primary"
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
									>
										<Span
											overflow-wrap="normal"
											word-break="normal"
											white-space="normal"
											text-indent="0"
											text-overflow="clip"
											hyphens="manual"
											user-select="auto"
											pointer-events="auto"
										>
											Twitter
										</Span>
									</Link>
									for regular updates and the latest news about the game!
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="image" />
			<Override slot="icon2" />
			<Override slot="box" />
			<Override slot="box2" />
			<Override slot="linkBox2" />
			<Override slot="linkBox3" />
			<Override slot="linkBox1" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});
