import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Image, LinkBox, Icon, Section } from "@quarkly/widgets";
import { FaTwitter } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { AiFillInstagram } from "react-icons/ai";
const defaultProps = {
	"background": "--color-darkL1",
	"padding": "30px 0 30px 0",
	"quarkly-title": "Footer",
	"md-padding": "30px 0 30px 0",
	"sm-padding": "20px 0 20px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"border-color": "#232a44",
			"md-flex-direction": "column",
			"sm-align-items": "center",
			"sm-justify-content": "center",
			"md-justify-content": "center",
			"md-align-items": "center"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-gap": "8px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "white",
			"children": "©2024 Hidden Jem Games"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-template-columns": "repeat(5, 1fr)",
			"grid-gap": "16px 24px",
			"sm-align-self": "center",
			"md-padding": "0px 0px 0px 0px",
			"md-margin": "16px 0px 0px 0px",
			"md-align-self": "auto"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.tiktok.com/@hiddenjem09"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"display": "block",
			"height": "28px",
			"src": "https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/937599284d2b94f35a8afb7db2e5533a%20%282%29.png?v=2024-12-15T17:36:20.493Z"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"href": "https://x.com/innertrailgame"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaTwitter,
			"size": "24px",
			"color": "#ffffff",
			"hover-color": "#a78bfa",
			"transition": "background-color 1s ease 0s"
		}
	},
	"linkBox2": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.youtube.com/@innertrailgame"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoLogoYoutube,
			"size": "28px",
			"color": "#ffffff",
			"hover-color": "#a78bfa",
			"transition": "background-color 1s ease 0s"
		}
	},
	"linkBox3": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.instagram.com/inner_trail_game"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "ai",
			"icon": AiFillInstagram,
			"size": "28px",
			"color": "#ffffff",
			"hover-color": "#a78bfa",
			"transition": "background-color 1s ease 0s",
			"font": "30px sans-serif"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" md-align-items="center" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
			</Box>
			<Box {...override("box2")}>
				<LinkBox {...override("linkBox")}>
					<Image {...override("image")} />
				</LinkBox>
				<LinkBox {...override("linkBox2")}>
					<Icon {...override("icon1")} />
				</LinkBox>
				<LinkBox {...override("linkBox3")}>
					<Icon {...override("icon2")} />
				</LinkBox>
				<LinkBox {...override("linkBox1")}>
					<Icon {...override("icon")} />
				</LinkBox>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;