import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, LinkBox, Icon, Box, Section } from "@quarkly/widgets";
import { FaTwitter } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { AiFillInstagram } from "react-icons/ai";
const defaultProps = {
	"padding": "16px 40px 16px 40px",
	"quarkly-title": "Header",
	"position": "absolute",
	"height": "80px",
	"sm-padding": "24px 20px 24px 20px",
	"sm-height": "80px",
	"md-height": "80px"
};
const overrides = {
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"href": "/index",
			"md-height": "auto",
			"md-width": "auto"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"display": "block",
			"alt": "hidden-jem-logo",
			"title": "Hidden Jem Logo",
			"src": "https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/Hidden_Jem_Logo-Transparent-Small.png?v=2024-12-11T00:04:18.430Z",
			"height": "80px",
			"padding": "10px 0 10px 0",
			"md-padding": "0px 0 0px 0",
			"md-height": "55px"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-template-columns": "repeat(5, 1fr)",
			"grid-gap": "16px 24px",
			"md-align-self": "auto"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.tiktok.com/@hiddenjem09"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"display": "block",
			"md-height": "28px",
			"sm-height": "26px",
			"height": "30px",
			"src": "https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/output-onlinepngtools.png?v=2024-12-22T21:03:51.630Z"
		}
	},
	"linkBox2": {
		"kind": "LinkBox",
		"props": {
			"href": "https://x.com/innertrailgame"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaTwitter,
			"size": "24px",
			"hover-color": "#a78bfa",
			"transition": "background-color 1s ease 0s",
			"md-font": "26px sans-serif",
			"sm-font": "22px sans-serif",
			"sm-width": "auto",
			"sm-height": "auto",
			"md-width": "auto",
			"md-height": "auto",
			"font": "26px sans-serif",
			"color": "--indigo"
		}
	},
	"linkBox3": {
		"kind": "LinkBox",
		"props": {
			"md-font": "20px sans-serif",
			"href": "https://www.youtube.com/@innertrailgame"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoLogoYoutube,
			"size": "28px",
			"hover-color": "#a78bfa",
			"transition": "background-color 1s ease 0s",
			"md-font": "26px sans-serif",
			"sm-font": "24px sans-serif",
			"color": "--color-indigo"
		}
	},
	"linkBox4": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.instagram.com/inner_trail_game"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "ai",
			"icon": AiFillInstagram,
			"size": "28px",
			"hover-color": "#a78bfa",
			"transition": "background-color 1s ease 0s",
			"md-font": "28px sans-serif",
			"sm-font": "26px sans-serif",
			"font": "30px sans-serif",
			"color": "--color-indigo"
		}
	},
	"SectionContent": {
		"props": {
			"md-align-items": "center"
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			align-items="center"
			display="flex"
			justify-content="space-between"
			width="100%"
			max-width="none"
		/>
		<LinkBox {...override("linkBox")}>
			<Image {...override("image")} />
		</LinkBox>
		<Box {...override("box")}>
			<LinkBox {...override("linkBox1")}>
				<Image {...override("image1")} />
			</LinkBox>
			<LinkBox {...override("linkBox3")}>
				<Icon {...override("icon1")} />
			</LinkBox>
			<LinkBox {...override("linkBox4")}>
				<Icon {...override("icon2")} />
			</LinkBox>
			<LinkBox {...override("linkBox2")}>
				<Icon {...override("icon")} />
			</LinkBox>
		</Box>
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;